


































import { Component, Vue } from 'vue-property-decorator';
import { CompanyModule } from '@/store/index';
import { CompanyService } from '@/shared/services/mater-data/company.service';
import CompanyFilters from './CompanyFilters/CompanyFilters.vue';
import CompanyModal from './CompanyModal/CompanyModal.vue';
import ToggleButton from '@/shared/components/form/ToggleButton.vue';
import { CompanyHeaders } from './models/CompanyHeaders';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { convertAPIToViewFullFormat } from '@/utils/date.util';
import { phoneFormater } from '@/utils/utils';
import { ToastHelper } from '@/utils/toast.util';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: {
    CompanyFilters,
    CompanyModal,
    ToggleButton,
    TmsTable
  }
})
export default class Company extends Vue {
  companyId = null;
  companyModule = CompanyModule;
  counter = 0;
  showModal = true;
  loading = false;
  disabled = false;
  headersList = CompanyHeaders;

  constructor() {
    super();
  }

  get tableData() {
    return this.companyModule.companies.map(company => ({
      ...company,
      ...{
        category: this.getCompanyCategory(company),
        createdDateLabel: company.createdDate
          ? convertAPIToViewFullFormat(company.createdDate)
          : '',
        cellPhoneLabel: phoneFormater(company.cellPhone),
        officePhoneLabel: phoneFormater(company.officePhone),
        faxLabel: phoneFormater(company.fax),
        type: company.vendor ? company.vendorType : company.customerType
      }
    }));
  }

  get isLastPage(): boolean {
    return !CompanyModule.meta.lastKey;
  }

  created() {
    this.disabled = PermissionsService.can('MASTER', 'COMPANY', 'View');
  }

  onAction({ data, key }: { key: string; data: any }) {
    this.changeCompany(data.id);
  }

  getCompanyCategory(company) {
    const category = company.vendor
      ? 'Vendor'
      : company.customer
      ? 'Customer'
      : company.cnee
      ? 'CNEE'
      : company.ngl
      ? 'NGL'
      : '';
    return category;
  }

  async changeActive(company) {
    this.loading = true;
    this.showModal = false;
    await CompanyService.changeCompanyActive(company.id, {
      active: company.active
    });

    this.loading = false;
  }

  onInputChange({ field, data }) {
    switch (field) {
      case 'active':
        this.changeActive(data);
        break;
      case 'tracking':
        this.changeTracking(data);
        break;
      default:
        break;
    }
  }

  async changeTracking(company) {
    this.loading = true;

    this.showModal = false;
    await CompanyService.changeCompanyTracking(company.id, {
      tracking: !company.tracking
    });

    this.loading = false;
  }

  private changeCompany(id) {
    if (this.showModal) {
      this.$bvModal.show('company-modal');
      this.companyId = id;
    }
    this.showModal = true;
  }
}
