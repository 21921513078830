











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CompanyService } from '@/shared/services/mater-data/company.service';
import { convertDateFieldToAPIFormat } from '@/utils/date.util';
import { validationMixin } from 'vuelidate';
import {
  companyValidation,
  companyData,
  companyVendorValidation,
  companyCustomerValidation
} from '../@types/company.model';
import { countries } from '@/shared/models/geo.model';
import { FileUploadService } from '@/shared/services/fileUpload.service';
import FileDownload from '@/shared/components/form/FileDownload.vue';
import ToggleButton from '@/shared/components/form/ToggleButton.vue';
import { ToastHelper } from '@/utils/toast.util';
import {
  divSelect,
  custTypeSelect,
  vendTypeSelect,
  termsSelect,
  w9Select,
  categorySelect
} from '../@types/sharedCompany.model';
import Loading from '@/shared/components/Loading.vue';
import { bankType } from '../../Driver/@types/sharedDriver.model';
import { states } from '../models/geo.model';
import { required } from 'vuelidate/lib/validators';
import { convertEmptyStringToNull } from '@/utils/form.util';

Component.registerHooks(['validations']);

@Component({
  components: {
    FileDownload,
    ToggleButton,
    Loading
  },
  mixins: [validationMixin]
})
export default class CompanyModal extends Vue {
  @Prop() companyId: any;
  @Prop() disabled: boolean;

  masterType = 'company';
  loading = false;
  convertDateFieldToAPIFormat = convertDateFieldToAPIFormat;
  fileUploadService = FileUploadService;
  saveClicked = false;
  categorySelected = 'vendor';
  radioCategories = ['vendor', 'cnee', 'ngl', 'customer'];
  masks = {
    telNumMask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ') ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ]
  };
  importedFiles = [];

  categoryOptions = categorySelect;
  divOptions = divSelect;
  custTypeOptions = custTypeSelect;
  vendTypeOptions = vendTypeSelect;
  termsOptions = termsSelect;
  w9Options = w9Select;
  countryOptions = countries;
  stateOptions = states;
  bankTypeOptions = bankType;
  errorModal = false;

  company = null;
  form: any = this.company;

  constructor() {
    super();
  }

  get companyValidation() {
    if (this.company.customer) {
      return companyCustomerValidation;
    }

    if (this.company.vendor) {
      return companyVendorValidation;
    }

    return companyValidation;
  }

  get requiredFields() {
    return new Set(
      Object.keys(this.companyValidation).filter(
        fieldName => this.companyValidation[fieldName].required
      )
    );
  }

  async changeCompany() {
    this.company = await CompanyService.getCompanyById(this.companyId);
    let files = JSON.parse(JSON.stringify(companyData.files));
    files.forEach((file, index, arr) => {
      this.company.files.forEach((companyFile, companyIndex, companyArr) => {
        if (file.category === companyFile.category) {
          arr[index] = { ...companyFile };
        }
      });
    });
    this.company.files = JSON.parse(JSON.stringify(files));
    this.importedFiles = JSON.parse(JSON.stringify(this.company.files));
    this.form = this.company;
    this.preselectCategory();
  }

  created() {
    if (this.companyId) {
      this.changeCompany();
    } else {
      this.company = JSON.parse(JSON.stringify(companyData));
      this.form = this.company;
    }
  }

  validations() {
    return {
      form: this.companyValidation
    };
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  async onFileUpload(file, index) {
    if (!file) {
      if (
        !this.companyId ||
        !this.importedFiles[index].filename ||
        !this.company.files[index].filename
      ) {
        this.company.files[index].documentName = null;
        this.company.files[index].filename = null;
        this.company.files[index].file = null;
        this.company.files[index].tempS3FileName = '';
        this.company.files[index].tempS3PreSignedUrl = '';
      } else {
        try {
          this.loading = true;

          await CompanyService.deleteCompanyFileById(
            this.companyId,
            this.company.files[index].category
          );

          this.company.files[index].documentName = null;
          this.company.files[index].filename = null;
          this.company.files[index].file = null;
          this.company.files[index].tempS3FileName = '';
          this.company.files[index].tempS3PreSignedUrl = '';

          this.loading = false;

          ToastHelper.show(
            'Company file delete',
            'File deletion successful',
            5000,
            'success'
          );
        } catch (err) {
          ToastHelper.show('Company file delete', err.message, 5000, 'danger');
        }
      }
    } else {
      this.company.files[index].filename = file.filename;
      this.company.files[index].documentName = file.documentName;
      this.company.files[index].file = file.file;
      this.company.files[index].tempS3FileName = file.tempS3FileName;
      this.company.files[index].tempS3PreSignedUrl = file.tempS3PreSignedUrl;
    }
  }

  preselectCategory() {
    this.radioCategories.forEach((filed: string) => {
      if (this.company[filed]) {
        this.categorySelected = filed;
      }
    });
  }

  selectRadioCategory(checked) {
    this.radioCategories.forEach((filed: string) => {
      this.company[filed] = false;
    });
    this.company[checked] = true;
  }

  async uploadFilesToDB(data) {
    const fileUploads = [];

    data.files.forEach((file, index) => {
      if (file?.file) {
        fileUploads.push(
          this.fileUploadService.uploadFile(file.tempS3PreSignedUrl, file.file)
        );
      }
    });

    await Promise.all(fileUploads);
  }

  changeActive() {
    this.company.active = !this.company.active;
  }

  changeCredit() {
    this.company.creditHold = !this.company.creditHold;
  }

  changeTracking() {
    this.company.tracking = !this.company.tracking;
  }

  closeCompany() {
    this.$bvModal.hide('company-modal');
  }

  async createCompany() {
    console.log(this.company);
    this.saveClicked = true;

    if (this.isFormInValid()) {
      return;
    }

    let toSendData = { ...this.company };

    [
      'officePhone',
      'cellPhone',
      'fax',
      'accountPhone',
      'accountFax',
      'vendorEin',
      'vendorSsn'
    ].forEach(key => {
      if (toSendData[key]) {
        toSendData[key] = toSendData[key].replace(/\D/g, '');
      }
    });

    convertEmptyStringToNull(toSendData);

    if (!toSendData.fax) {
      toSendData.fax = null;
    }

    let itemsToDeleteIfUpdate = [
      'createdBy',
      'createdDate',
      'updatedBy',
      'updatedDate',
      'id'
    ];

    let itemsToDeleteFromFilesIfUpdate = [
      'id',
      'createdBy',
      'createdDate',
      'updatedBy',
      'updatedDate'
    ];
    toSendData.vendorSsn = toSendData.vendorSsn ? toSendData.vendorSsn : null;
    toSendData.vendorEin = toSendData.vendorEin ? toSendData.vendorEin : null;

    toSendData.email =
      toSendData.email && typeof toSendData.email === 'string'
        ? (<string>toSendData.email).replaceAll(' ', '')
        : null;
    toSendData.accountEmail =
      toSendData.accountEmail && typeof toSendData.accountEmail === 'string'
        ? (<string>toSendData.accountEmail).replaceAll(' ', '')
        : null;

    if (this.companyId) {
      try {
        itemsToDeleteIfUpdate.forEach(item => {
          delete toSendData[item];
        });

        itemsToDeleteFromFilesIfUpdate.forEach(item => {
          toSendData.files.forEach(file => {
            delete file[item];
          });
        });

        this.loading = true;
        await this.uploadFilesToDB(toSendData);

        toSendData.files = toSendData.files.filter(
          file => !!file.tempS3FileName
        );
        toSendData.files.forEach((file, index, arr) => {
          delete toSendData.files[index].file;
        });

        await CompanyService.saveCompany(this.companyId, toSendData);
        this.loading = false;

        ToastHelper.show(
          'Company update',
          'Update successful',
          5000,
          'success'
        );
        this.$bvModal.hide('company-modal');
      } catch (err) {
        ToastHelper.show('Company update', err.message, 5000, 'danger');
      }
    } else {
      try {
        this.loading = true;
        await this.uploadFilesToDB(toSendData);
        toSendData.files = toSendData.files.filter(file => !!file.filename);
        toSendData.files.forEach((file, index, arr) => {
          delete toSendData.files[index].file;
        });

        await CompanyService.createCompany(toSendData);
        this.loading = false;

        ToastHelper.show(
          'Company create',
          'Create successful',
          5000,
          'success'
        );
        this.$bvModal.hide('company-modal');
      } catch (err) {
        ToastHelper.show('Company create', err.message, 5000, 'danger');
      }
    }
    console.log('[Finished]: ', toSendData);
  }

  isFormInValid() {
    this.$v.form.$touch();

    if (this.$v.form.$anyError) {
      this.errorModal = true;
    }

    return this.$v.form.$anyError;
  }
}
