import {
  CellContentType,
  TableHeaders
} from '@/shared/components/table/models';

export const CompanyHeaders: TableHeaders[] = [
  {
    key: 'category',
    label: 'Category'
  },
  {
    key: 'type',
    label: 'Type'
  },
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'name',
    label: 'Title'
  },
  {
    key: 'city',
    label: 'City'
  },
  {
    key: 'state',
    label: 'State'
  },
  {
    key: 'zip',
    label: 'ZIP'
  },
  {
    key: 'country',
    label: 'Country'
  },
  {
    key: 'contactName',
    label: 'Contact Name'
  },
  {
    key: 'cellPhoneLabel',
    label: 'Cell #'
  },
  {
    key: 'officePhoneLabel',
    label: 'Office'
  },
  {
    key: 'email',
    label: 'Email'
  },
  {
    key: 'faxLabel',
    label: 'Fax'
  },
  {
    key: 'createdDateLabel',
    label: 'Date'
  },
  {
    key: 'tracking',
    label: 'Tracking ID',
    content: { type: CellContentType.Switcher }
  },
  {
    key: 'active',
    label: 'Active',
    content: { type: CellContentType.Switcher }
  }
];
